<template>
  <div>
    <base-template
        module="cashFlowJournal"
        :field-filters="$store.getters['payment/getFieldFilters']"
        :default-filters="defaultView?defaultView.filters:[]"
        :default-sorts="defaultView?defaultView.sorts:[]"
        :items="payments"
        :items-context="paymentsContext"
        :is-list-view-action-column-enabled="true"
        :is-view-windows-ready="displayedPayment!=null"
        :allow-view-windows="true"
        :allow-add="true"
        ref="baseTemplate"

        @updatePayload="$emit('updatePayload', $event)"
        @addItem="addPayment"
        @showDetails="showDetails"
    >
      <template #listView_cell_paymentMethod="{item}">
        {{ $t(item.paymentMethod.name) }}
      </template>

      <template #listView_cell__paymentBeneficiaries="{item}">
        {{ item._paymentBeneficiaries.join(', ') }}
      </template>

      <template #listView_cell__paymentRelationsDisplay="{item}">
        {{ item._paymentRelationsDisplay.join(', ') }}
      </template>

      <template #listView_cell_status="{item}">
        <b-badge
            pill
            :variant="getStatusBadgeColor(item)"
        >
          <small v-if="item.bankTransaction!= null">
            {{ $t('reconciliated') }}
          </small>
          <small v-else>{{ $t('unreconciliated') }}</small>
        </b-badge>
      </template>


      <template #listView_cell_actions="{item}">
        <!--          Edit -->
        <button-edit
            @click.native.stop="editPayment(item)"
            v-b-tooltip.hover.left="$t('Edit')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Delete-->
        <button-remove
            v-b-tooltip.hover.left="capitalize($t('delete'))"
            @click.native.stop="deletePaymentAlert(item)"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />
      </template>


      <template #leftViewWindow="{item}">
        <payment-card
            :payment="item"
            :is-downloadable="false"
            :is-viewable="true"
            :is-selected="item.id==displayedPayment.id"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>
      <template #viewWindowTitle>
        {{ displayedPayment.date|moment('LL') }} - {{
          displayedPayment._paymentBeneficiaries.join(', ')
        }}
      </template>
      <template #viewWindowButtons>
        <!--          Edit -->
        <!--        <button-edit-->
        <!--            @click.native.stop="editSupplierInvoiceLocal(displayedSupplierInvoice)"-->
        <!--            v-b-tooltip.hover.left="$t('Edit')"-->
        <!--            :withIcon="true"-->
        <!--            :withText="false"-->
        <!--            :withBorder="false"-->
        <!--            size="sm"-->
        <!--        />-->
      </template>
      <template #windowView>
        <payment-view :payment="displayedPayment"/>
      </template>

    </base-template>

    <modal-payment
        :payment.sync="currentPayment"
        :title="'id' in currentPayment?$t('EditPayment'):$t('NewPayment')"
        :isOpen.sync="paymentModalShow"
        :is-empty-payment-creation="isEmptyPaymentCreation"

        @submitValidated="submitValidatedPaymentLocal"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import useAPI from '../../../../utils/useAPI'

import BaseTemplate from '../../../../components/base2/Base.vue'
import ButtonRemove from '../../../../components/button/Remove.vue'
import ButtonEdit from '../../../../components/button/Edit.vue'
import PaymentView from '../../../../components/views/PaymentView.vue'
import PaymentCard from '../../../../components/card/Payment.vue'
import ModalPayment from '../../../../components/prompt/Payment2.vue'
import store from '../../../../store'
import { getUserData } from '../../../../utils/utils'
import i18n from '../../../../libs/i18n'
import { usePayments } from '../../usePayment'
import { capitalize } from '../../../../utils/filter'

export default {
  components: { ModalPayment, PaymentCard, PaymentView, ButtonEdit, ButtonRemove, BaseTemplate },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const displayedPayment = ref(null)
    const currentPayment = ref({})
    const paymentModalShow = ref(false)
    const isEmptyPaymentCreation = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { payments, paymentsContext } = useAPI()

    const defaultView = computed(() => {
      return store.getters['moduleView/getModuleView'](getUserData().defaultViews.cashFlowJournal.id)
    })

    const bankAccount = computed(() => {
      if (paymentsContext.value.filters.length && paymentsContext.value.filters[0].sectionFilters.length) {
        let ba = paymentsContext.value.filters[0].sectionFilters.find(g => g.field == 'bankAccount')

        if (ba !== undefined) {
          return {
            id: parseInt(ba.value)
          }
        } else {
          return null
        }
      } else {
        return null
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { submitValidatedPayment } = usePayments()
    const resetPayment = () => {
      currentPayment.value = JSON.parse(JSON.stringify(store.getters['payment/getEmptyPayment']))
    }

    const showDetails = (payment) => {
      if (payment != null) {
        store.dispatch('payment/getPayment', payment.id)
            .then(response => {
              displayedPayment.value = response
            })

      } else {
        resetPayment()
      }
    }

    const addPayment = () => {
      currentPayment.value = {
        paymentMethod: { id: 1, name: i18n.t('Bank transfert') },
        date: (new Date()).toISOString().slice(0, 10),
        paymentRelations: [],
        bankAccount: bankAccount
      }
      isEmptyPaymentCreation.value = true
      paymentModalShow.value = true
    }

    const editPayment = (payment) => {
      if (payment != null) {
        store.dispatch('payment/getPayment', payment.id)
            .then(response => {
              isEmptyPaymentCreation.value = true
              currentPayment.value = response
              paymentModalShow.value = true
            })
      }
    }

    const removePayment = (payment) => {
      return new Promise((resolve, reject) => {
        store.dispatch('payment/removePayment', payment)
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              console.log(error)
              reject(error)
            })
      })
    }

    const submitValidatedPaymentLocal = () => {
      // console.log(JSON.parse(JSON.stringify(currentPayment.value)))
      submitValidatedPayment(currentPayment.value)
          .then(response => {
            currentPayment.value = {}
            // openViewWindow.value = false
            paymentModalShow.value = false
          })
    }

    const getStatusBadgeColor = (payment) => {
      if (payment.bankTransaction != null) {
        return 'success'
      } else {
        return 'warning'
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      displayedPayment,
      paymentModalShow,
      currentPayment,
      isEmptyPaymentCreation,

      // Computed
      payments,
      paymentsContext,
      defaultView,

      // Methods
      addPayment,
      editPayment,
      removePayment,
      submitValidatedPaymentLocal,
      showDetails,
      getStatusBadgeColor,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deletePaymentAlert (payment) {
      this.$bvModal
          .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('thePayment') }), {
            okVariant: 'danger',
            okTitle: this.capitalize(this.$t('delete')),
            cancelVariant: 'outline-secondary',
            cancelTitle: this.capitalize(this.$t('cancel')),
            centered: true,
          })
          .then(value => {
            if (value == true) {
              this.removePayment(payment)
            }
          })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>