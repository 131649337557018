<template>
  <div>
    <base-template
        v-if="isReady"
        ref="baseTemplate"
        :items="bankTransactions"
        :items-context="bankTransactionsContext"
        :available-filters="$store.getters['bankTransaction/getAvailableFilters']"
        :payload.sync="payload"
        :module="module"
        :is-list-view-action-column-enabled="true"
        :allow-add="false"
        :allow-add-by-upload="true"
        :allow-view-windows="true"
        :is-view-windows-ready="displayedBankTransaction!=null"

        @addItemByUpload="bankStatementUploadModalShow=true"
        @showDetails="showDetails"
    >
      <template #listView_cell_paymentMethod="{item}">
        <span class="text-nowrap">{{ item.paymentMethod ? $t(item.paymentMethod.name) : '' }}</span>
      </template>

      <template #listView_cell__bankTransactionRelationsDisplay="{item}">
        <span class="text-nowrap">{{ item._bankTransactionRelationsDisplay.join(', ') }}</span>
      </template>

      <template #listView_cell_status="{item}">
        <div class="text-center">
          <b-badge
              pill
              :variant="getPaymentRelationStatus(item)=='partialReconciliation'?'danger':getPaymentRelationStatusBadgeColor(getPaymentRelationStatus(item)=='reconciliated')"
          >
            <small>{{ $t(getPaymentRelationStatus(item)) }}</small>
          </b-badge>
        </div>
      </template>
      <template #listView_cell_actions="{item}">
        <!--          Link-->
        <button-link
            @click.native.stop="linkToPayment(item)"
            v-b-tooltip.hover.left="$t('Link to a payment')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            :is-disabled="item.payments.length != 0"
            size="sm"
        />
      </template>

      <template #leftViewWindow="{item}">
        <card-bank-transaction
            :bank-transaction="item"
            :is-downloadable="false"
            :is-viewable="true"
            :is-selected="item.id==displayedBankTransaction.id"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>
      <template #viewWindowTitle>
        {{ displayedBankTransaction.date|moment('LL') }} - {{ displayedBankTransaction.wording }}
      </template>
      <template #viewWindowButtons>
      </template>
      <template #windowView>
        <bank-transaction-view :bank-transaction="displayedBankTransaction"/>
      </template>
    </base-template>

    <!-- modal bank statements by upload-->
    <modal-upload
        :singleFile.sync="bankStatementFile"

        :documentType="'bankStatement'"
        :title="capitalize($t('bankStatement'))"
        :isOpen.sync="bankStatementUploadModalShow"
        :accepted-types="notOnlyOfx?['ofx', 'pdf']:['ofx']"
    >
      <template #top>
        <div class="d-flex">
          <custom-switch
              :model.sync="notOnlyOfx"
              :displayLabel="false"
          />
          <span>{{ $t('AllowPdfFiles') }}</span>
        </div>
      </template>
    </modal-upload>

    <!-- modal bank statements-->
    <modal-bank-transaction
        v-if="currentBankTransaction != null"
        :bank-transaction.sync="currentBankTransaction"
        :bank-transaction-best-matchs="bankTransactionBestMatchs"
        :bank-account="bankAccount"
        :title="$t('bank reconciliation')"
        :isOpen.sync="bankTransactionModalShow"
        :display-bank-account="true"

        @submitedElements="submitValidatedBankTransactionLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import BaseTemplate             from '../../../../components/base3/Base.vue'
import ButtonRemove             from '../../../../components/button/Remove.vue'
import ButtonEdit               from '../../../../components/button/Edit.vue'
import useAPI                   from '../../../../utils/useAPI'
import store                    from '../../../../store'
import { capitalize }           from '../../../../utils/filter'
import ButtonLink               from '../../../../components/button/Link.vue'
import CardBankTransaction      from '../../../../components/card/BankTransaction.vue'
import BankTransactionView      from '../../../../components/views/BankTransactionView.vue'
import ModalUpload              from '../../../../components/prompt/Upload.vue'
import CustomSwitch             from '../../../../components/input/Switch.vue'
import ModalBankTransaction     from '../../../../components/prompt/BankTransaction.vue'
import i18n                     from '../../../../libs/i18n'

export default {
  components: {
    ModalBankTransaction,
    CustomSwitch,
    ModalUpload, BankTransactionView, CardBankTransaction, ButtonLink, ButtonEdit, ButtonRemove, BaseTemplate
  },
  props: {},
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const baseTemplate = ref()
    const module = ref('bankTransactions')
    const payload = ref({})
    const isReady = ref(false)
    const isAutocompleteLoaded = ref(false)

    const displayedBankTransaction = ref(null)
    const currentBankTransaction = ref(null)
    const bankTransactionModalShow = ref(false)
    const bankStatementUploadModalShow = ref(false)
    const bankStatementFile = ref(null)
    const notOnlyOfx = ref(false)
    const bankTransactionBestMatchs = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { bankTransactions, bankTransactionsContext } = useAPI()

    const bankAccount = computed(() => {
      let ba = store.getters['bankTransaction/getBankTransactionsContext']

      if (ba.filters.length) {
        ba.filters.forEach(f => {
          if (f.sectionFilters.find(g => g.field == 'bankAccount') !== undefined) {
            ba = f.sectionFilters.find(g => g.field == 'bankAccount')
          }
        })

        if (ba !== undefined) {
          return {
            id: parseInt(ba.value)
          }
        } else {
          return null
        }
      } else {
        return null
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(payload, val => {
      isReady.value = true
      emit('updatePayload', val)
    }, { deep: true })

    watch(bankStatementFile, (val) => {
      if (val != null) {
        recognizeBankStatements(val)
            .then(response => {
              store.commit('bankTransaction/SET_BANKTRANSACTIONS', response.data.results)
              store.commit('bankTransaction/SET_BANKTRANSACTIONSCONTEXT', response.data.page_context)

              store.dispatch('bankAccount2/getBankAccountPayments', { bankAccountId: bankAccount.value.id })

              bankStatementFile.value = null
              bankStatementUploadModalShow.value = false
            })
            .catch(() => {
              bankStatementFile.value = null
              bankStatementUploadModalShow.value = false
            })
      }

    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const showDetails = (bankTransaction) => {
      if (bankTransaction != null) {
        store.dispatch('bankTransaction/getBankTransaction', bankTransaction.id)
            .then(response => {
              displayedBankTransaction.value = response
            })

      } else {
        resetBankTransaction()
      }
    }

    const resetBankTransaction = () => {
      currentBankTransaction.value = JSON.parse(JSON.stringify(store.getters['bankTransaction/getEmptyBankTransaction']))
    }

    const recognizeBankStatements = (bankStatementDocument) => {
      // console.log(bankStatementDocument)
      return new Promise((resolve, reject) => {
        store.dispatch('document/recognizeDocument', [bankStatementDocument, 'bankStatement'])
            .then(response => {
              // console.log(response)
              resolve(response)
            })
            .catch(error => {
              console.log(error)
              reject(error)
            })
      })
    }

    const getPaymentRelationStatus = (bankTransaction) => {
      if (
          (
              bankTransaction.payments != null &&
              bankTransaction.payments.length != 0
          ) ||
          bankTransaction.linkedTransaction != null ||
          bankTransaction.linkedBankTransaction != null ||
          (
              bankTransaction.attachment != null &&
              bankTransaction.attachmentCategory != null
          )
      ) {
        if (
            parseFloat(bankTransaction.payments.reduce((x, item) => x + parseFloat(item.amount), 0)) !=
            parseFloat(bankTransaction.debit ? bankTransaction.debit : -bankTransaction.credit)) {

          // console.log(bankTransaction.payments.reduce((x, item) => x + parseFloat(item.amount), 0))
          // console.log(bankTransaction.debit ? bankTransaction.debit : -bankTransaction.credit)

          return 'partialReconciliation'
        } else {
          return 'reconciliated'
        }
      } else {
        return 'unreconciliated'
      }
    }

    const getPaymentRelationStatusBadgeColor = (relation) => {
      if (relation) {
        return 'success'
      } else {
        return 'warning'
      }
    }

    const linkToPayment = (bankTransaction) => {
      if (bankTransaction) {
        // console.log(bankTransaction)
        if (bankTransaction.payments.length) {
          showDetails(bankTransaction)
        } else {
          currentBankTransaction.value = JSON.parse(JSON.stringify(bankTransaction))
          // cashFlowInitial.value = structuredClone(payments)
          // cashFlowInitialContext.value = structuredClone(paymentsContext)

          // console.log(bankAccount.value)

          store.dispatch('bankTransaction/getBankTransactionBestMatch', bankTransaction)
              .then(response => {
                // console.log(response)
                bankTransactionBestMatchs.value = response
                bankTransactionModalShow.value = true
              })
        }
      }
    }

    const submitValidatedBankTransactionLocal = (element) => {
      // console.log(element)

      let currentBankTransactionLocal = JSON.parse(JSON.stringify(currentBankTransaction.value))
      let elementKeys = Object.keys(element).map(key => {if (element[key] != null) {return key}}).filter(x => x != undefined)

      // console.log(elementKeys)

      if (elementKeys.length == 1) {

        if (elementKeys[0] == 'payment') {
          let payment = element[elementKeys[0]]
          // console.log(payment)
          // console.log(currentBankTransaction.value)

          payment.bankTransaction = currentBankTransaction.value.id
          store.dispatch('payment/updatePayment', payment)
              .then((p) => {
                if (!currentBankTransactionLocal.payments.some(ps => ps.id == p.data.id)) {
                  currentBankTransactionLocal.payments.push(p.data)
                  store.commit('bankTransaction/UPDATE_BANKTRANSACTION', currentBankTransactionLocal)
                }
              })
          resetModal()
        } else if (elementKeys[0] == 'supplierInvoices') {

        } else if (elementKeys[0] == 'customerInvoices') {

        } else if (elementKeys[0] == 'bankTransaction') {
          let paymentCurrentBankAccount = {
            amount: element.bankTransaction.debit != null ? element.bankTransaction.debit : element.bankTransaction.credit,
            bankAccount: bankAccount.value,
            bankTransaction: currentBankTransaction.value,
            date: element.bankTransaction.date,
            paymentMethod: element.bankTransaction.paymentMethod,
            paymentRelations: [],
            reference: capitalize(i18n.t('internalPayment'))
          }

          let paymentOtherBankAccount = {
            amount: element.bankTransaction.debit != null ? element.bankTransaction.debit : -element.bankTransaction.credit,
            bankAccount: element.bankTransaction.bankAccount,
            bankTransaction: null,
            date: element.bankTransaction.date,
            paymentMethod: element.bankTransaction.paymentMethod,
            paymentRelations: [],
            reference: capitalize(i18n.t('internalPayment'))
          }

          // console.log('paymentCurrentBankAccount', paymentCurrentBankAccount)
          // console.log('paymentOtherBankAccount', paymentOtherBankAccount)

          store.dispatch('payment/addPayment', paymentCurrentBankAccount)
              .then((p) => {
                currentBankTransactionLocal.payments.push(p.data)
                store.commit('bankTransaction/UPDATE_BANKTRANSACTION', currentBankTransactionLocal)

                store.dispatch('payment/addPayment', paymentOtherBankAccount)
                    .then(() => {
                      resetModal()
                    })
              })

        } else if (elementKeys[0] == 'salary') {

        } else if (elementKeys[0] == 'charges') {

        }

      }
    }

    const resetModal = () => {
      bankTransactionModalShow.value = false
      currentBankTransaction.value = null
      bankTransactionBestMatchs.value = null
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetBankTransaction()

    let currentView = store.getters['moduleView/getCurrentModuleView'](module.value)

    payload.value = {
      filters: currentView.filters,
      kanbanSortBy: currentView.kanbanSortBy,
      kanbanColumnAmount: currentView.kanbanColumnAmount,
      listGlobalAmount: currentView.listGlobalAmount,
      page: 1,
      sorts: currentView.sorts,
    }

    return {
      // Components
      capitalize,

      // Data
      module,
      payload,
      isReady,

      displayedBankTransaction,
      currentBankTransaction,
      bankTransactionModalShow,
      bankStatementUploadModalShow,
      bankStatementFile,
      notOnlyOfx,
      bankTransactionBestMatchs,

      // Computed
      bankTransactions,
      bankTransactionsContext,
      bankAccount,

      // Methods
      showDetails,
      getPaymentRelationStatus,
      getPaymentRelationStatusBadgeColor,
      linkToPayment,
      submitValidatedBankTransactionLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteBankTransactionAlert (bankTransaction) {
      this.$bvModal
          .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theBankTransaction') }), {
            okVariant: 'danger',
            okTitle: this.capitalize(this.$t('delete')),
            cancelVariant: 'outline-secondary',
            cancelTitle: this.capitalize(this.$t('cancel')),
            centered: true,
          })
          .then(value => {
            if (value == true) {
              this.removeBankTransaction(bankTransaction)
            }
          })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>