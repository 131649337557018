<template>
  <div>
    <base-template
        v-if="isReady"
        ref="baseTemplate"
        :items="payments"
        :items-context="paymentsContext"
        :available-filters="$store.getters['payment/getAvailableFilters']"
        :payload.sync="payload"
        :module="module"
        :is-list-view-action-column-enabled="true"
        :allow-view-windows="$can('manage', 'contactPayments')"
        :is-view-windows-ready="displayedPayment!=null"

        @addItem="addPayment"
        @showDetails="showDetails"
    >
      <template #listView_cell_paymentMethod="{item}">
        {{ $t(item.paymentMethod.name) }}
      </template>
      <template #listView_cell__paymentBeneficiaries="{item}">
        {{ item._paymentBeneficiaries.join(', ') }}
      </template>
      <template #listView_cell__paymentRelationsDisplay="{item}">
        {{ item._paymentRelationsDisplay.join(', ') }}
      </template>
      <template #listView_cell_status="{item}">
        <b-badge
            pill
            :variant="getStatusBadgeColor(item)"
        >
          <small>{{ $t(item._status) }}</small>
        </b-badge>
      </template>

      <template #listView_cell_actions="{item}">
        <!--          Edit -->
        <button-edit
            @click.native.stop="editPayment(item)"
            v-b-tooltip.hover.left="$t('Edit')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Delete-->
        <button-remove
            v-b-tooltip.hover.left="capitalize($t('delete'))"
            @click.native.stop="deletePaymentAlert(item)"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />
      </template>

      <template #leftViewWindow="{item}">
        <payment-card
            :payment="item"
            :is-downloadable="false"
            :is-viewable="true"
            :is-selected="item.id==displayedPayment.id"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>
      <template #viewWindowTitle>
        {{ displayedPayment.date|moment('LL') }} - {{ displayedPayment._paymentBeneficiaries.join(', ') }}
      </template>
      <template #viewWindowButtons>
        <!--          Edit -->
        <button-edit
            @click.native.stop="editPayment(displayedPayment)"
            v-b-tooltip.hover.left="$t('Edit')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />
      </template>
      <template #windowView>
        <payment-view :payment="displayedPayment"/>
      </template>

    </base-template>


    <!-- modal payments-->
    <modal-payment
        :payment.sync="currentPayment"
        :title="'id' in currentPayment?$t('EditPayment'):$t('NewPayment')"
        :isOpen.sync="paymentModalShow"
        :is-empty-payment-creation="isEmptyPaymentCreation"

        @submitValidated="submitValidatedPaymentLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../../utils/filter'
import { usePayments } from '../../usePayment'

import useAPI from '../../../../utils/useAPI'
import store from '../../../../store'
import i18n from '../../../../libs/i18n'

import BaseTemplate from '../../../../components/base3/Base.vue'
import ButtonEdit from '../../../../components/button/Edit.vue'
import ButtonRemove from '../../../../components/button/Remove.vue'
import PaymentCard from '../../../../components/card/Payment.vue'
import PaymentView from '../../../../components/views/PaymentView.vue'
import ModalPayment from '../../../../components/prompt/Payment2.vue'

export default {
  components: {
    ModalPayment,
    PaymentView,
    PaymentCard,
    ButtonRemove,
    ButtonEdit,
    BaseTemplate,
  },
  props: {
    selectedBankAccount: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const baseTemplate = ref()
    const module = ref('cashFlowJournal')
    const payload = ref({})
    const isReady = ref(false)
    const isAutocompleteLoaded = ref(false)

    const displayedPayment = ref(null)
    const currentPayment = ref(null)
    const paymentModalShow = ref(false)
    const isEmptyPaymentCreation = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { payments, paymentsContext } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(payload, val => {
      isReady.value = true
      emit('updatePayload', val)
    }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { submitValidatedPayment } = usePayments()

    const showDetails = (payment) => {
      if (payment != null) {
        store.dispatch('payment/getPayment', payment.id)
            .then(response => {
              displayedPayment.value = response
            })

      } else {
        resetPayment()
      }
    }

    const resetPayment = () => {
      currentPayment.value = JSON.parse(JSON.stringify(store.getters['payment/getEmptyPayment']))
    }

    const addPayment = () => {
      currentPayment.value = {
        paymentMethod: { id: 1, name: i18n.t('Bank transfert') },
        date: (new Date()).toISOString().slice(0, 10),
        paymentRelations: [],
        bankAccount: props.selectedBankAccount
      }
      isEmptyPaymentCreation.value = true
      paymentModalShow.value = true

      console.log(isEmptyPaymentCreation.value)
    }

    const editPayment = (payment) => {
      if (payment != null) {
        store.dispatch('payment/getPayment', payment.id)
            .then(response => {
              isEmptyPaymentCreation.value = true
              currentPayment.value = response
              paymentModalShow.value = true
            })
      }
    }

    const removePayment = (payment) => {
      return new Promise((resolve, reject) => {
        store.dispatch('payment/removePayment', payment)
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              console.log(error)
              reject(error)
            })
      })
    }

    const submitValidatedPaymentLocal = () => {
      // console.log(JSON.parse(JSON.stringify(currentPayment.value)))
      submitValidatedPayment(currentPayment.value)
          .then(response => {
            currentPayment.value = {}
            // openViewWindow.value = false
            paymentModalShow.value = false
          })
    }

    const getStatusBadgeColor = (payment) => {
      if (payment._status == 'Partially reconciled') {
        return 'danger'
      } else if (payment._status == 'Reconciled') {
        return 'success'
      } else if (payment._status == 'Unreconciled') {
        return 'warning'
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetPayment()

    let currentView = store.getters['moduleView/getCurrentModuleView'](module.value)

    payload.value = {
      filters: currentView.filters,
      kanbanSortBy: currentView.kanbanSortBy,
      kanbanColumnAmount: currentView.kanbanColumnAmount,
      listGlobalAmount: currentView.listGlobalAmount,
      page: 1,
      sorts: currentView.sorts,
    }

    return {
      // Components
      capitalize,

      // Data
      module,
      payload,
      isReady,

      displayedPayment,
      currentPayment,
      paymentModalShow,
      isEmptyPaymentCreation,

      // Computed
      payments,
      paymentsContext,

      // Methods
      showDetails,
      addPayment,
      editPayment,
      removePayment,
      submitValidatedPaymentLocal,
      getStatusBadgeColor,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deletePaymentAlert (payment) {
      this.$bvModal
          .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('thePayment') }), {
            okVariant: 'danger',
            okTitle: this.capitalize(this.$t('delete')),
            cancelVariant: 'outline-secondary',
            cancelTitle: this.capitalize(this.$t('cancel')),
            centered: true,
          })
          .then(value => {
            if (value == true) {
              this.removePayment(payment)
            }
          })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>