<template>
  <div>

    <chart-line
        :series="series"
        :labels="labels"
        :with-legend="true"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../../utils/filter'
import { isEmptyObject } from '../../../../utils/utils'

import ChartLine from '../../../../components/chart/Line.vue'
import useAPI from '../../../../utils/useAPI'
import i18n from '../../../../libs/i18n'

export default {
  components: {
    ChartLine,
  },
  props: {
    bankAccount: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { bankAccountIndicators } = useAPI()

    const series = computed(() => {
      if (isEmptyObject(bankAccountIndicators.value) || !labels.value.length) {
        return []
      }

      let bankBalance = []
      let estimate = []
      let realKeyValues = bankAccountIndicators.value.cashFlows.real.chart
      let estimateKeyValues = bankAccountIndicators.value.cashFlows.estimated.chart

      labels.value.forEach(monthStr => {
        bankBalance.push((monthStr in realKeyValues)?realKeyValues[monthStr]:null)
        estimate.push((monthStr in estimateKeyValues)?estimateKeyValues[monthStr]:null)
      })

      return [{
        name: capitalize(i18n.t('bankBalance')),
        data: bankBalance
      }, {
        name: capitalize(i18n.t('estimate')),
        data: estimate
      },]

    })

    const labels = computed(() => {
      if (isEmptyObject(bankAccountIndicators.value)) {
        return []
      }

      return Object.keys(bankAccountIndicators.value.cashFlows.real.chart).concat(Object.keys(bankAccountIndicators.value.cashFlows.estimated.chart).slice(1))
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => props.bankAccount, val => {
      getIndicators()
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { fetchBankAccountIndicators } = useAPI()

    const getIndicators = () => {
      fetchBankAccountIndicators({
        bankAccount: props.bankAccount,
        start: '2022-07-01',
        end: '2023-06-31'
      })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    getIndicators()

    return {
      // Components

      // Data

      // Computed
      series,
      labels,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>