<template>
  <div style="min-height: 312.19px">

    <div class="w-100 d-flex justify-content-between">
      <div class="text-nowrap">
        {{ bankTransaction.date|moment('L') }}
      </div>

      <div class="w-100 text-truncate pl-2">
        {{ bankTransaction.wording }}
      </div>

      <div
          v-if="bankTransaction.debit"
          class="text-nowrap text-danger pl-2">
        -{{ currency(bankTransaction.debit) }}
      </div>

      <div
          v-else
          class="text-nowrap text-success pl-2">
        +{{ currency(bankTransaction.credit) }}
      </div>

      <!--      <div class="text-nowrap text-danger px-2">-->
      <!--        {{ currency(bankTransaction.debit) }}-->
      <!--      </div>-->

      <!--      <div class="text-nowrap text-success">-->
      <!--        {{ currency(bankTransaction.credit) }}-->
      <!--      </div>-->

    </div>

    <div class="w-100">
      <i>
        <small>
        <span v-html="htmlComment"></span>
        </small>
      </i>
    </div>

    <div
        v-if="bankTransaction.payments.length"
        class="mt-4">
      <h2>{{ capitalize($t('correspondingPayment:')) }}</h2>

      <payment-view
          v-for="(payment, index) in bankTransaction.payments"
          class="mb-2 p-2 bg-primary bg-lighten-5 rounded"
          :payment="payment"
          :allow-delete="true"

          @removeBankTransactionPayment="deleteBankReconciliationAlert(index)"
      />
    </div>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { currency, capitalize } from '../../utils/filter'

import PaymentView from './PaymentView.vue'
import store from '../../store'

export default {
  components: {
    PaymentView
  },
  props: {
    bankTransaction: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const htmlComment = computed(() => {
      // console.log(props.bankTransaction)
      if (props.bankTransaction.comment != null) {
        return JSON.parse(JSON.stringify(props.bankTransaction.comment)).replace(/(\\r\\n|\\r|\\n)/g, '<br>')
      } else {
        return ""
      }

    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const removeBankTransactionPayment = (index) => {
      let payment = props.bankTransaction.payments[index]

      store.dispatch('payment/updatePayment', payment)
          .then(() => {
            props.bankTransaction.payments.splice(index, 1)
            store.dispatch('bankTransaction/getBankTransaction', props.bankTransaction.id)
          })
    }

    // const getHtmlComment = () => {
    //
    // }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      currency,
      capitalize,

      // Data

      // Computed
      htmlComment,

      // Methods
      removeBankTransactionPayment,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteBankReconciliationAlert (index) {
      this.$bvModal
          .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theBankReconciliation') }), {
            okVariant: 'danger',
            okTitle: this.capitalize(this.$t('delete')),
            cancelVariant: 'outline-secondary',
            cancelTitle: this.capitalize(this.$t('cancel')),
            centered: true,
          })
          .then(value => {
            if (value == true) {
              this.removeBankTransactionPayment(index)
            }
          })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>