<template>
  <div>
    <select2
        :model.sync="localModel"
        :options="payments"
        :multiple="multiple"
        :name="name"
        :label="label"
        :filter="filterPayment"

        @input="$emit('input', $event)"
    >

      <template #customList="{option}">
        <div v-if="'_paymentBeneficiaries' in option">
          <div class="d-flex">
            <div class="w-100 text-truncate">
              <span v-if="option._paymentBeneficiaries.length">{{ option._paymentBeneficiaries.join(', ') }}</span>
              <span v-else>-</span>
            </div>
            <div class="text-right text-primary">
              {{ currency(option.amount) }}
            </div>
          </div>
          <div class="d-flex font-italic">
            <div>
              <small>{{ option.date|moment('L') }}</small>
            </div>
            <div class="w-100 text-right text-truncate">
              <small v-if="showBankAccount">{{ option.bankAccount._display }}</small>
              <small v-else>
                {{ option._paymentRelationsDisplay.join(', ') }}
              </small>
            </div>
          </div>
        </div>

      </template>

    </select2>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../../utils/filter'
import { comparableString, getObjectId } from '../../../utils/utils'

import vSelect from 'vue-select'
import select2 from '../Select2.vue'
import store from '../../../store'
import moment from 'moment'
import useAPI from '../../../utils/useAPI'

export default {
  components: {
    vSelect,
    select2
  },
  props: {
    model: {
      // required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    onlyNotCompletelyPaid: {
      type: Boolean,
      default: false
    },
    displayLabel: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    listIndex: {
      type: Number,
      default: -1
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    showBankAccount: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localModel = ref(props.model)
    const name = ref('payment')
    const label = ref('_display')

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { payments } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(localModel, () => {
      emit('update:model', localModel.value)
      emit('input', localModel.value)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const filterPayment = (options, search) => {
      return options.filter(p => {
        if (props.showBankAccount) {
          return (
              (
                  p._paymentBeneficiaries.length &&
                  compare(
                      comparableString(p._paymentBeneficiaries.join(',')),
                      comparableString(search)
                  )
              ) ||
              compare(comparableString(p.amount), comparableString(search)) ||
              compare(comparableString(moment(p.date).format('L')), comparableString(search)) || compare(comparableString(p.bankAccount._display), comparableString(search))
          )
        } else {
          return (
              (
                  p._paymentBeneficiaries.length &&
                  compare(
                      comparableString(p._paymentBeneficiaries.join(',')),
                      comparableString(search)
                  )
              ) ||
              compare(comparableString(p.amount), comparableString(search)) ||
              compare(comparableString(moment(p.date).format('L')), comparableString(search)) ||
              compare(comparableString(p._paymentRelationsDisplay.join(',')), comparableString(search))
          )
        }

      })
    }

    const compare = (label, search) => {
      return (label || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      currency,

      // Data
      localModel,
      name,
      label,

      // Computed
      payments,

      // Methods
      filterPayment,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>