<template>
  <div>

    <!--    Bank account cards-->
    <b-row>
      <b-col
          md="4"
          v-for="(ba, index) in bankAccounts2"
          :key="index"
      >
        <bank-account-card
            :bank-account="ba"
            :is-selected="selectedBankAccount == ba"

            @click="selectedBankAccount = ba"
        />

      </b-col>
    </b-row>

    <!--    Tabs-->
    <b-row v-if="selectedBankAccount != null">
      <b-col>
        <b-tabs justified>
          <b-tab>
            <template #title>
              <icon icon="chart-line"/>
              <span>{{ $t('Dashboard') }}</span>
            </template>
            <bank-account-dashboard :bank-account="selectedBankAccount"/>

          </b-tab>
          <b-tab active>
            <template #title>
              <icon icon="cash-register"/>
              <span>{{ $t('CashFlowJournal') }}</span>
            </template>

            <cash-flow-journal3
                v-if="isModuleViewsReady"
                :selected-bank-account="selectedBankAccount"

                @updatePayload="updateReload('cashFlowJournal', $event)"
            />

          </b-tab>
          <b-tab>
            <template #title>
              <icon icon="file-medical-alt"/>
              <span>{{ $t('BankStatements') }}</span>
            </template>

            <bank-transactions3

                @updatePayload="updateReload('bankTransactions', $event)"
            />
          </b-tab>

        </b-tabs>
      </b-col>
    </b-row>


  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import useAPI from '../../../utils/useAPI'
import store from '../../../store'
import BankAccountCard from '../../../components/card/BankAccount'
import BankAccountDashboard from './components/Dashboard'
import BankAccountCashFlowJournal from './components/CashFlowJournal'
import BankAccountBankTransactions from './components/BankTransactions'
// import CashFlowJournal from './components/CashFlowJournal'
import CashFlowJournal from './components/CashFlowJournal2.vue'
import CashFlowJournal3 from './components/CashFlowJournal3.vue'
import BankTransactions3 from './components/BankTransactions3.vue'
import { getRoute } from '../../../utils/utils'

export default {
  components: {
    // CashFlowJournal,
    BankAccountCard,
    CashFlowJournal3,
    BankAccountDashboard,
    // BankAccountCashFlowJournal,
    // BankAccountBankTransactions,
    BankTransactions3
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const selectedBankAccount = ref(null)
    const isInitialRequest = ref(true)

    const currentCashFlowJournalPayload = ref('{}')
    const currentBankTransactionsPayload = ref('{}')
    const isModuleViewsReady = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const {
      bankAccounts2,
      fetchAutocompleteBankAccounts,
      fetchAutocompletePaymentMethods
    } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(selectedBankAccount, val => {
      if (isInitialRequest.value == false) {
        updateReload('cashFlowJournal', {})
        updateReload('bankTransactions', {})
      } else {
        isInitialRequest.value = false
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchBankAccounts2,
      fetchAutocompleteDocumentCategories,
      fetchAutocompleteContacts,
      fetchBankTransactions,
      fetchPayments,
    } = useAPI()

    const updateReload = (type, payload) => {
      if ('filters' in payload) {
        if (payload.filters.length == 0) {

          payload.filters.push({
            sectionLogicOperator: 'AND',
            sectionFilters: [
              {
                logicOperator: 'AND',
                field: 'bankAccount',
                operator: 'equal',
                value: selectedBankAccount.value.id
              },
            ]
          })
        } else {
          let filterIndex = -1
          let sectionFilters = -1
          payload.filters.forEach((f, i)=> {
            if (sectionFilters == -1) {
              filterIndex = i
              sectionFilters = f.sectionFilters.findIndex(g => g.field == 'bankAccount')
            }
          })

          if (sectionFilters == -1) {
            payload.filters.push({
              sectionLogicOperator: 'AND',
              sectionFilters: [
                {
                  logicOperator: 'AND',
                  field: 'bankAccount',
                  operator: 'equal',
                  value: selectedBankAccount.value.id
                },
              ]
            })
          } else {
            payload.filters[filterIndex].sectionFilters[sectionFilters] = {
              logicOperator: 'AND',
              field: 'bankAccount',
              operator: 'equal',
              value: selectedBankAccount.value.id
            }
          }
        }
      } else {
        payload.filters = [{
          sectionLogicOperator: 'AND',
          sectionFilters: [
            {
              logicOperator: 'AND',
              field: 'bankAccount',
              operator: 'equal',
              value: selectedBankAccount.value.id
            },
          ]
        }]
      }

      // console.log(JSON.stringify(payload))

      if (type == 'cashFlowJournal') {
        if (JSON.stringify(payload) != currentCashFlowJournalPayload.value) {
          currentCashFlowJournalPayload.value = JSON.stringify(payload)
          store.dispatch('payment/getPayments', payload)
        }
      } else if (type == 'bankTransactions') {
        if (JSON.stringify(payload) != currentBankTransactionsPayload.value) {
          currentBankTransactionsPayload.value = JSON.stringify(payload)
          store.dispatch('bankTransaction/getBankTransactions', payload)
        }
      }

    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    store.dispatch('moduleView/getModuleViews')
        .then((response) => {
          fetchBankAccounts2()
              .then(() => {
                if (bankAccounts2.value.length) {
                  selectedBankAccount.value = bankAccounts2.value[0]

                  isModuleViewsReady.value = true
                }

                fetchAutocompleteDocumentCategories()
                fetchAutocompleteContacts()
                fetchAutocompleteBankAccounts()
                fetchAutocompletePaymentMethods({
                      filters: [{
                        sectionLogicOperator: 'AND',
                        sectionFilters: [
                          {
                            logicOperator: 'AND',
                            operator: 'not_equal',
                            field: 'name',
                            value: 'Employee'
                          }
                        ]
                      }]
                    }
                )
              })

        })

    return {
      // Components

      // Data
      selectedBankAccount,
      isModuleViewsReady,

      // Computed
      bankAccounts2,

      // Methods
      // updateBankTransactions,
      // updateCashFlowJournal,
      updateReload,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>