var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isReady)?_c('base-template',{ref:"baseTemplate",attrs:{"items":_vm.payments,"items-context":_vm.paymentsContext,"available-filters":_vm.$store.getters['payment/getAvailableFilters'],"payload":_vm.payload,"module":_vm.module,"is-list-view-action-column-enabled":true,"allow-view-windows":_vm.$can('manage', 'contactPayments'),"is-view-windows-ready":_vm.displayedPayment!=null},on:{"update:payload":function($event){_vm.payload=$event},"addItem":_vm.addPayment,"showDetails":_vm.showDetails},scopedSlots:_vm._u([{key:"listView_cell_paymentMethod",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.paymentMethod.name))+" ")]}},{key:"listView_cell__paymentBeneficiaries",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item._paymentBeneficiaries.join(', '))+" ")]}},{key:"listView_cell__paymentRelationsDisplay",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item._paymentRelationsDisplay.join(', '))+" ")]}},{key:"listView_cell_status",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"pill":"","variant":_vm.getStatusBadgeColor(item)}},[_c('small',[_vm._v(_vm._s(_vm.$t(item._status)))])])]}},{key:"listView_cell_actions",fn:function(ref){
var item = ref.item;
return [_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(_vm.$t('Edit')),expression:"$t('Edit')",modifiers:{"hover":true,"left":true}}],attrs:{"withIcon":true,"withText":false,"withBorder":false,"size":"sm"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.editPayment(item)}}}),_c('button-remove',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(_vm.capitalize(_vm.$t('delete'))),expression:"capitalize($t('delete'))",modifiers:{"hover":true,"left":true}}],attrs:{"withIcon":true,"withText":false,"withBorder":false,"size":"sm"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.deletePaymentAlert(item)}}})]}},{key:"leftViewWindow",fn:function(ref){
var item = ref.item;
return [_c('payment-card',{attrs:{"payment":item,"is-downloadable":false,"is-viewable":true,"is-selected":item.id==_vm.displayedPayment.id},on:{"click":function($event){return _vm.showDetails(item)},"view":function($event){return _vm.showDetails(item)}}})]}},{key:"viewWindowTitle",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("moment")(_vm.displayedPayment.date,'LL'))+" - "+_vm._s(_vm.displayedPayment._paymentBeneficiaries.join(', '))+" ")]},proxy:true},{key:"viewWindowButtons",fn:function(){return [_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(_vm.$t('Edit')),expression:"$t('Edit')",modifiers:{"hover":true,"left":true}}],attrs:{"withIcon":true,"withText":false,"withBorder":false,"size":"sm"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.editPayment(_vm.displayedPayment)}}})]},proxy:true},{key:"windowView",fn:function(){return [_c('payment-view',{attrs:{"payment":_vm.displayedPayment}})]},proxy:true}],null,false,812668432)}):_vm._e(),_c('modal-payment',{attrs:{"payment":_vm.currentPayment,"title":'id' in _vm.currentPayment?_vm.$t('EditPayment'):_vm.$t('NewPayment'),"isOpen":_vm.paymentModalShow,"is-empty-payment-creation":_vm.isEmptyPaymentCreation},on:{"update:payment":function($event){_vm.currentPayment=$event},"update:isOpen":function($event){_vm.paymentModalShow=$event},"update:is-open":function($event){_vm.paymentModalShow=$event},"submitValidated":_vm.submitValidatedPaymentLocal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }