<template>
  <generic-content
      :isLinkable="false"
      :isEditable="true"
      :isDeletable="isDeletable"
      :isDownloadable="false"
      @click="$emit('click')"
      @edit="$emit('edit')"
      @delete="$emit('delete')"
  >
    <template #iconContent>
      <b-img
          style="height: 50px"
          :src="require('@/assets/images/banks/'+bankAccount.bank+'.png')"
          alt="logo"
      />
    </template>

    <template #bodyContent>
      <h6 class="transaction-title text-truncate mb-1">
        {{ bankAccount._display }}
      </h6>
      <div class="d-flex justify-content-between text-truncate">
        <small>
          <span
              class="font-weight-bolder"
              :class="bankAccount.balance>=0?'text-success':'text-danger'"
          >
            {{ currency(bankAccount.balance) }}
          </span>
        </small>
        <small>
          <i>
            {{ bankAccount.balanceDate|moment('L') }}
          </i>
        </small>
      </div>
    </template>

  </generic-content>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { currency } from '../../../utils/filter'

import GenericContent from './Content'
import i18n from '@/libs/i18n'
import bankAccount from '../../../store/bankAccount/bankAccount'

export default {
  components: {
    GenericContent
  },
  props: {
    bankAccount: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isDeletable = computed(() => {
      return false
      // return props.bankAccount.bankOperations.length == 0
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      currency,

      // Data

      // Computed
      isDeletable,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {

  },
  created () {
  }
}
</script>

<style lang="scss">

</style>