<template>
    <div>

        <base-template
                itemView="list"
                :text-button-add="$t('addPayment')"
                :columns="columns"
                :filters="filters"
                :items="payments"
                :context="paymentsContext"
                :isActionColumnEnabled="true"
                :openViewWindow.sync="openViewWindow"
                :allowAdd="true"
                selectable="single"

                @fetch="fetchPayload = $event"
                @rowSelected="showDetails($event[0])"
                @addItem="addPayment"
        >
            <!--  =========================== -->
            <!--  ===      Grid view      === -->
            <!--  =========================== -->
            <template v-slot:gridView="{item}">
                <payment-card
                        :payment="item"
                        :is-downloadable="false"
                        :is-viewable="true"

                        @click="showDetails(item)"
                        @view="showDetails(item)"
                />
            </template>

            <!--  =========================== -->
            <!--  ===      List view      === -->
            <!--  =========================== -->
            <!--      Actions-->
            <template #listView_cell_actions="{item}">
                <div class="text-nowrap">

                    <!--          View-->
                    <button-view
                            @click.native.stop="showDetails(item)"
                            v-b-tooltip.hover.left="$t('View')"
                            :withIcon="true"
                            :withText="false"
                            :withBorder="false"
                            size="sm"
                    />

                    <!--          Edit-->
                    <button-edit
                            @click.native="editPayment(item)"
                            v-b-tooltip.hover.left="capitalize($t('Edit'))"
                            :withIcon="true"
                            :withText="false"
                            :withBorder="false"
                            :disabled="item.bankTransaction!=null"
                            size="sm"
                    />

                    <!--          Download-->
                    <button-download
                            @click.native="downloadFile(item)"
                            :withIcon="true"
                            :withText="false"
                            :withBorder="false"
                            :disabled="isDownloadDisabled(item)"
                            size="sm"
                    />

                    <!--          Delete-->
                    <button-remove
                            @click.native.stop="deletePaymentAlert(item)"
                            v-b-tooltip.hover.left="capitalize($t('delete'))"
                            :withIcon="true"
                            :withText="false"
                            :withBorder="false"
                            size="sm"
                    />

                </div>
            </template>


            <!--  =========================== -->
            <!--  ===     Select view     === -->
            <!--  =========================== -->
            <template #leftViewWindow="{item}">
                <payment-card
                        :payment="item"
                        :is-downloadable="false"
                        :is-viewable="true"
                        :is-selected="item.id==selectedItems[0].id"

                        @click="showDetails(item)"
                        @view="showDetails(item)"
                />
            </template>
            <template #windowView
                      v-if="selectedItems.length">
                <div class="d-flex justify-content-between mb-1">
                    <h4>{{ selectedItems[0].date|moment('LL') }} - {{
                        selectedItems[0]._paymentBeneficiaries.join(', ')
                        }}</h4>
                    <div>
                        <button-edit
                                @click.native="editPayment(selectedItems[0])"
                                v-b-tooltip.hover.left="capitalize($t('Edit'))"
                                :withIcon="true"
                                :withText="false"
                                :withBorder="false"
                                :disabled="selectedItems[0].bankTransaction!=null"
                                size="sm"
                        />

                        <button-download
                                @click.native="downloadFile(selectedItems[0])"
                                v-b-tooltip.hover.left="capitalize($t('downloadAttachedFile'))"
                                :withIcon="true"
                                :withText="false"
                                :withBorder="false"
                                :disabled="isDownloadDisabled(selectedItems[0])"
                                size="sm"
                        />

                        <button-close
                                @click.native="showDetails(null)"
                                v-b-tooltip.hover.left="capitalize($t('Close'))"
                                :withIcon="true"
                                :withText="false"
                                :withBorder="false"
                                size="sm"
                        />
                    </div>
                </div>

                <payment-view
                        :payment="selectedItems[0]"
                />

            </template>
        </base-template>

        <modal-payment
                :payment.sync="currentPayment"
                :title="'id' in currentPayment?$t('EditPayment'):$t('NewPayment')"
                :isOpen.sync="paymentModalShow"
                :formMaxAmount="maxPaymentAmount"
                :is-empty-payment-creation="isEmptyPaymentCreation"

                @submitValidated="submitValidatedPaymentLocal"
        />

    </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../../utils/filter'
import { usePayments } from '../../usePayment'
import { downloadByDocument, getUserData } from '../../../../utils/utils'

import i18n from '../../../../libs/i18n'
import store from '../../../../store'
import useAPI from '../../../../utils/useAPI'
import BaseTemplate from '../../../../components/base/Base.vue'
import PaymentCard from '../../../../components/card/Payment'
import ButtonView from '../../../../components/button/View'
import ButtonEdit from '../../../../components/button/Edit'
import ButtonClose from '../../../../components/button/Close'
import ButtonRemove from '../../../../components/button/Remove'
import ButtonDownload from '../../../../components/button/Download'
import PaymentView from '../../../../components/views/PaymentView.vue'
import ModalPayment from '../../../../components/prompt/Payment2'
import WorkFloCard from '../../../../components/card/WorkFlo.vue'
import CompanyView from '../../../../components/views/CompanyView.vue'
import CompanyCard from '../../../../components/card/CompanyCard.vue'

export default {
  components: {
    CompanyCard, CompanyView,
    WorkFloCard,
    BaseTemplate,
    PaymentCard,
    ButtonView,
    ButtonEdit,
    ButtonClose,
    ButtonRemove,
    ButtonDownload,
    PaymentView,
    ModalPayment
  },
  props: {
    bankAccount: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const columns = ref([
      {
        display: capitalize(i18n.t('date')),
        key: 'date',
        sortable: true,
        cell: {
          isDate: true
        }
      },
      {
        display: capitalize(i18n.t('paymentMethod')),
        key: 'paymentMethod',
        sortable: true,
        cell: {
          useName: true,
          translate: true,
        }
      },
      {
        display: capitalize(i18n.t('beneficiaries')),
        key: '_paymentBeneficiaries',
        sortable: true,
        cell: {}
      },
      {
        display: capitalize(i18n.t('invoices')),
        key: '_paymentRelationsDisplay',
        sortable: true,
        cell: {}
      },
      {
        display: capitalize(i18n.t('status')),
        key: 'status',
        sortable: false,
        cell: {
          useBadge: true,
          type: 'paymentBankTransactionRelationStatus',
          center: true
        }
      },
      {
        display: capitalize(i18n.t('amount')),
        key: 'amount',
        sortable: true,
        cell: {
          isCurrency: true
        }
      },
    ])
    const filters = ref([
      {
        text: capitalize(i18n.tc('reconciliated')),
        key: '_filter_isReconciliated',
        searchCriteria: [{
          column_name: 'bankOperationsPaymentRelation',
          comparator: 'notNull',
        }],
      },
      {
        text: capitalize(i18n.tc('unreconciliated')),
        key: '_filter_isUnreconciliated',
        searchCriteria: [{
          column_name: 'bankOperationsPaymentRelation',
          comparator: 'null',
        }],
      },
      {
        text: capitalize(i18n.tc('currentFiscalYear')),
        key: '_filter_isCurrentFiscalYear',
        searchCriteria: [{
          column_name: 'date',
          comparator: 'range',
          search_text_0: '2022-07-01',
          search_text_1: '2023-06-30'
        }],
        // selected: true,
      },
    ])
    const openViewWindow = ref(false)
    const selectedItems = ref([])
    const currentPayment = ref({})
    const paymentModalShow = ref(false)
    const maxPaymentAmount = ref(null)
    const fetchPayload = ref({})
    const isEmptyPaymentCreation = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { payments, paymentsContext } = useAPI()

    const defaultView = computed(() => {
      // console.log(store.getters['moduleView/getModuleView'](getUserData().defaultViews.cashFlowJournal.id))
      return store.getters['moduleView/getModuleView'](getUserData().defaultViews.cashFlowJournal.id)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => props.bankAccount, val => {
      getPayments()
    })

    watch(fetchPayload, val => {
      fetchPayload.value.bankAccountId = props.bankAccount.id
      store.dispatch('bankAccount2/getBankAccountPayments', fetchPayload.value)
    })

    watch(paymentModalShow, val => {
      if (val == false) {
        isEmptyPaymentCreation.value = false
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { submitValidatedPayment } = usePayments()

    const getPayments = () => {
      fetchPayload.value.bankAccountId = props.bankAccount.id
      store.dispatch('bankAccount2/getBankAccountPayments', fetchPayload.value)
    }

    const rowSelected = (rowSelected) => {
      selectedItems.value = rowSelected
    }

    const showDetails = (payment) => {
      if (payment != null) {
        store.dispatch('payment/getPayment', payment.id)
          .then(response => {
            selectedItems.value = [response]
            openViewWindow.value = true
          })

      } else {
        selectedItems.value = []
        openViewWindow.value = false
      }
    }

    const editPayment = (payment) => {
      if (payment != null) {
        store.dispatch('payment/getPayment', payment.id)
          .then(response => {
            isEmptyPaymentCreation.value = true
            currentPayment.value = response
            paymentModalShow.value = true
          })
      }
    }

    const submitValidatedPaymentLocal = () => {
      // console.log(JSON.parse(JSON.stringify(currentPayment.value)))
      submitValidatedPayment(currentPayment.value)
        .then(response => {
          currentPayment.value = {}
          selectedItems.value = []
          maxPaymentAmount.value = null
          openViewWindow.value = false
          paymentModalShow.value = false
        })
    }

    const removePayment = (payment) => {
      return new Promise((resolve, reject) => {
        store.dispatch('payment/removePayment', payment)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    }

    const isDownloadDisabled = (item) => {
      if (item.attachment != null ||
        (
          item.paymentRelations.length == 1 &&
          item.paymentRelations[0].salary != null
        ) || (
          item.paymentRelations.length == 1 &&
          item.paymentRelations[0].socialSecurityContribution != null
        )) {
        return false
      } else {
        return true
      }
    }

    const downloadFile = (item) => {
      if (item.attachment != null) {
        downloadByDocument(item.attachment)
      }
    }

    const addPayment = () => {
      currentPayment.value = {
        paymentMethod: { id: 1, name: i18n.t('Bank transfert') },
        date: (new Date()).toISOString().slice(0, 10),
        paymentRelations: [],
        bankAccount: props.bankAccount
      }
      isEmptyPaymentCreation.value = true
      paymentModalShow.value = true
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    getPayments()

    return {
      // Components
      capitalize,

      // Data
      columns,
      filters,
      openViewWindow,
      selectedItems,
      currentPayment,
      paymentModalShow,
      maxPaymentAmount,
      fetchPayload,
      isEmptyPaymentCreation,

      // Computed
      payments,
      paymentsContext,
      defaultView,

      // Methods
      rowSelected,
      showDetails,
      // addPayment,
      editPayment,
      submitValidatedPaymentLocal,
      removePayment,
      isDownloadDisabled,
      downloadFile,
      addPayment,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deletePaymentAlert (payment) {
      this.$bvModal
        .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('thePayment') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('delete')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.removePayment(payment)
          }
        })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>