<template>
  <div>
    <bank-transaction-card
        :bank-transaction="bankTransaction"
        :is-downloadable="false"
        class="mb-2 bg-primary bg-lighten-5"
    />

    <switch-field
        class="w-100 text-right"
        name="internalPayment"
        :model.sync="isInternalPaymentLocal"
    />

    <collapse
        accordion
        type="margin"
        class="mb-2"
    >

      <collapse-item
          :title="capitalize($tc('betterMatch', paymentLength)) +' ('+ paymentLength + ')'"
          :is-visible="true"
          collapse-class="darkCollapse"
      >
        <div v-if="isInternalPaymentLocal == true && bankTransactionBestMatchs.bankTransactions != null && bankTransactionBestMatchs.bankTransactions.length != 0">
          <bank-transaction-card
              v-for="bankTransaction in bankTransactionBestMatchs.bankTransactions"
              :bankTransaction="bankTransaction"
              :display-bank-account="true"

              @click.native="$emit('bestMatchBankTransactionSelected')"
          />
        </div>
        <div v-else-if="isInternalPaymentLocal == false && bankTransactionBestMatchs.payments != null && bankTransactionBestMatchs.payments.length != 0">
          <payment-card
              v-for="payment in bankTransactionBestMatchs.payments"
              :payment="payment"
              :is-downloadable="false"

              @click.native="$emit('bestMatchPaymentSelected')"
          />
        </div>
        <div v-else>
          {{ $t('No match found') }}
        </div>


        <!--                <div-->
        <!--                        v-if="bankTransactionBestMatchs.payments != null && bankTransactionBestMatchs.payments.length != 0"-->
        <!--                >-->
        <!--                    <payment-card-->
        <!--                            v-for="payment in bankTransactionBestMatchs.payments"-->
        <!--                            :payment="payment"-->
        <!--                            :is-downloadable="false"-->

        <!--                            @click.native="$emit('bestMatchPaymentSelected')"-->
        <!--                    />-->
        <!--                </div>-->
        <!--                <div v-else-if="isInternalPaymentLocal == true">-->
        <!--                    <bank-transaction-card-->
        <!--                            v-for="bankTransaction in bankTransactionBestMatchs.bankTransactions"-->
        <!--                            :bankTransaction="bankTransaction"-->
        <!--                            :display-bank-account="true"-->

        <!--                            @click.native="$emit('bestMatchBankTransactionSelected')"-->
        <!--                    />-->
        <!--                </div>-->
        <!--                <div v-else>-->
        <!--                    {{ $t('No match found') }}-->
        <!--                </div>-->

      </collapse-item>

      <collapse-item
          v-if="!isInternalPaymentLocal"
          :title="capitalize($t('search')) +' ('+ payments.length + ')'"
          :is-visible="false"
          collapse-class="darkCollapse"
      >
        <b-alert
            v-if="selectedPaymentLocal && (selectedPaymentLocal.amount != (bankTransaction.debit?bankTransaction.debit:bankTransaction.credit))"
            show
            variant="danger"
        >
          <div class="alert-body d-flex justify-content-between">
            <icon
                icon="exclamation-triangle"
                class="top-0 my-auto"
            />
            <span class="ml-50 w-100 my-auto">
              {{ $t('The amounts do not match') }}
            </span>
          </div>
        </b-alert>

        <select-payment
            :model.sync="selectedPaymentLocal"
            :show-bank-account="false"
        />
      </collapse-item>


    </collapse>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../utils/filter'

import PaymentCard from '../../card/Payment.vue'
import BankTransactionCard from '../../card/BankTransaction.vue'
import Collapse from '../../collapse/Collapse.vue'
import CollapseItem from '../../collapse/CollapseItem.vue'
import SelectPayment from '../../input/custom/SelectPayment.vue'
import SwitchField from '../../input/Switch.vue'
import useAPI from '../../../utils/useAPI'

export default {
  components: {
    CollapseItem,
    Collapse,
    BankTransactionCard,
    PaymentCard,
    SelectPayment,
    SwitchField
  },
  props: {
    bankTransaction: {
      type: Object,
      default: () => {}
    },
    bankTransactionBestMatchs: {
      type: Object,
      default: () => {}
    },
    bankAccount: {
      type: Object,
      default: () => {}
    },
    selectedElements: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const selectedPaymentLocal = ref(null)
    const isInternalPaymentLocal = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { payments } = useAPI()

    const paymentLength = computed(() => {
      if (props.bankTransactionBestMatchs.payments != null) {
        return props.bankTransactionBestMatchs.payments.length
      } else if (isInternalPaymentLocal.value == true) {
        return props.bankTransactionBestMatchs.bankTransactions.length
      } else {
        return 0
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(selectedPaymentLocal, val => {
      props.selectedElements.payment = val
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { fetchPayments } = useAPI()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    // fetchPayments({
    //   searchCriteria: [{
    //     column_name: 'bankTransaction',
    //     comparator: 'null'
    //   }, {
    //     column_name: 'bankAccount',
    //     comparator: '=',
    //     search_text: props.bankAccount.id
    //   },],
    //   perPage: 5000
    // })
    fetchPayments({
      filters: [
        {
          sectionLogicOperator: 'AND',
          sectionFilters: [
            {
              logicOperator: 'AND',
              operator: 'is_empty',
              field: 'bankTransaction',
            }
          ]
        },
        {
          sectionLogicOperator: 'AND',
          sectionFilters: [
            {
              logicOperator: 'AND',
              operator: 'equal',
              field: 'bankAccount',
              value: props.bankAccount.id
            }
          ]
        }
      ],
      per_page: 5000
    })

    return {
      // Components
      capitalize,

      // Data
      selectedPaymentLocal,
      isInternalPaymentLocal,

      // Computed
      payments,
      paymentLength,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>