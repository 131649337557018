<template>
  <b-modal
      id="1"
      :title="title"
      v-model="isOpenLocal"
      :size="selectedTab==0?'sm':'xl'"
      scrollable
      :hide-footer="hideFooter"
      :ok-title="capitalize($t('validate'))"
      :cancel-title="capitalize($t('cancel'))"
      @hide="tryCloseBankTransactionModal"
      @hidden="handleCancel"
      @ok="handleOk"
  >
    <validation-observer
        ref="observerRef"
        #default="{handleSubmit, errors}">
      <b-form
          @keydown.enter.stop.prevent=""
          @submit.prevent="handleSubmit(submitValidatedBankTransactionLocal)"
      >
        <form-bank-transaction-match
            :bankTransaction.sync="bankTransactionLocal"
            :bank-transaction-best-matchs="bankTransactionBestMatchs"
            :bank-account="bankAccount"
            :selected-elements.sync="selectedElements"

            @bestMatchPaymentSelected="bestMatchPaymentSelected"
            @bestMatchBankTransactionSelected="bestMatchBankTransactionSelected"
        />
<!--        <form-bank-transaction-match-->
<!--            :bankTransaction.sync="bankTransactionLocal"-->
<!--            :bank-transaction-best-matchs="bankTransactionBestMatchs"-->
<!--            :display-bank-account="displayBankAccount"-->
<!--            :formErrors="errors"-->
<!--            :selected-elements.sync="selectedElements"-->

<!--            @selectedTab="selectedTab=$event"-->

<!--            @submitWithPayment="$emit('submitWithPayment', $event)"-->
<!--            @submitWithIncomingInvoice="$emit('submitWithIncomingInvoice')"-->
<!--            @submitWithOutgoingInvoice="$emit('submitWithOutgoingInvoice')"-->
<!--            @submitWithBankTransaction="$emit('submitWithBankTransaction')"-->
<!--            @submitWithSalary="$emit('submitWithSalary')"-->
<!--            @submitWithCharge="$emit('submitWithCharge')"-->
<!--            @submitWithOther="$emit('submitWithOther', $event)"-->

<!--            @submit="submitValidatedBankTransactionLocal"-->
<!--        />-->

        <button ref="submitBankTransactionFormRef"
                class="d-none"></button>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { capitalize } from '../../utils/filter'
import { isObject } from '../../utils/utils'

import useAPI from '../../utils/useAPI'
import FormBankTransactionMatch from '../form/bankTransactionMatchs/BankTransactionMatch.vue'

export default {
  components: {
    ValidationObserver,
    FormBankTransactionMatch
  },
  props: {
    bankTransaction: {
      type: Object,
      default: () => {}
    },
    bankTransactionBestMatchs: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    formMaxAmount: {
      type: Number,
      default: null
    },
    displayBankAccount: {
      type: Boolean,
      default: false
    },
    bankAccount: {
      type: Object,
      default: () => {}
    }

  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isOpenLocal = ref(false)
    const bankTransactionLocal = ref(props.bankTransaction)
    const submitBankTransactionFormRef = ref(null)
    const observerRef = ref(null)
    const selectedElements = ref({
      payment: null,
      customerInvoices: null,
      supplierInvoices: null,
      bankTransaction: null,
      salary: null,
      charges: null,
      other: null
    })
    const selectedTab= ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { currentBreakPoint, bankAccounts } = useAPI()

    const hideFooter = computed(() => {
      return !['xs', 'sm'].includes(currentBreakPoint.value)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => props.isOpen, val => {
      isOpenLocal.value = val
    })
    watch(() => props.bankTransaction, val => {
      bankTransactionLocal.value = val
    })

    watch(isOpenLocal, (val) => {
      emit('update:isOpen', val)
    })
    watch(bankTransactionLocal, (val) => {
      emit('update:bankTransaction', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const tryCloseBankTransactionModal = (modalEvent) => {
      if (modalEvent.trigger == 'backdrop') {
        // Click outside
        modalEvent.preventDefault()
        submitBankTransactionFormRef.value.click()
      }
    }

    const bestMatchPaymentSelected = () => {
      selectedElements.value.payment = props.bankTransactionBestMatchs.payments[0]
      submitValidatedBankTransactionLocal()
    }

    const bestMatchBankTransactionSelected = () => {
      selectedElements.value.bankTransaction = props.bankTransactionBestMatchs.bankTransactions[0]
      submitValidatedBankTransactionLocal()
    }

    const submitValidatedBankTransactionLocal = () => {
      // console.log(selectedElements.value)
      emit('submitedElements', selectedElements.value)
      // emit('submitWithPayment', 'payment')
    }

    const handleOk = async (bvModalEvent) => {
      bvModalEvent.preventDefault()

      const isValid = await observerRef.value.validate()
      if (isValid) {
        submitValidatedBankTransactionLocal()
      }
    }

    const handleCancel = () => {}

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      isOpenLocal,
      bankTransactionLocal,
      submitBankTransactionFormRef,
      observerRef,
      selectedElements,
      selectedTab,

      // Computed
      hideFooter,
      bankAccounts,

      // Methods
      tryCloseBankTransactionModal,
      bestMatchPaymentSelected,
      bestMatchBankTransactionSelected,
      submitValidatedBankTransactionLocal,
      handleOk,
      handleCancel,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    // bankTransaction: function (val) {
    //   this.bankTransactionLocal = val
    // },
    // isOpen: function (val) {
    //   this.isOpenLocal = val
    // },
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.modal-body {
  background-color: #f8f8f8;
}
</style>